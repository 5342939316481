import React from 'react';

import { ColorTokenProp, ColorToken } from '../../../theme/tokens/color';
import { ButtonColorToken } from '../../../theme/tokens/button-color';
import { mapTokenProp } from '../../../theme/tokens/utils';

import { PhoneNumberWrapper } from '../../CtaWidget/LegacyPhoneNumberWidget/PhoneNumberWidget.styles';

import { DEFAULT_BUTTON_TEXT } from '../../CtaWidget/LegacyPhoneNumberWidget';
import {
  ctaWidgetEnum,
  DEFAULT_LABEL,
  DEFAULT_PLACEHOLDER
} from '../../CtaWidget/shared';
import { NodeProps } from './shared';

import { LinkButton } from '../../Button';
import { Flexbox } from '../../Flexbox';
import { Box } from '../../Box';
import { Text } from '../../Text';
import { ImageWith7030TextEmbed } from '../../ImageWith7030Text/ImageWith7030TextEmbed';
import CtaWidget from '../../CtaWidget';
import { PageBlocks } from '../../Page/PageBlocks';

import { PageBlockPositioner } from '../styles';
import { BannerCTA } from '~/components/LearningCenter/Components/BannerCTA';
import { ILearningCenterBannerCtaFields } from '@/types/contentful';

interface EmbeddedEntryNodeProps extends NodeProps {
  colour: ColorTokenProp | string;
}

export function EmbeddedEntryNode({ node, colour }: EmbeddedEntryNodeProps) {
  const { fields, sys } = node.data?.target ?? {};

  switch (sys?.contentType?.sys?.id) {
    case 'button':
      const { file, description } = fields?.icon?.fields ?? {};
      const iconSrc = file?.url ? `https:${file?.url}` : undefined;
      const iconAlt = description ?? undefined;

      return (
        <LinkButton
          href={fields?.url}
          target={fields?.openInNewTab ? '_blank' : ''}
          rel="noopener noreferrer"
          buttonColor={mapTokenProp<ColorToken, ButtonColorToken>(
            colour,
            (c) => (c === 'white' ? 'light' : 'dark')
          )}
          buttonHoverColor={mapTokenProp<ColorToken, ButtonColorToken>(
            colour,
            (c) => (c === 'white' ? 'lightHover' : 'darkHover')
          )}
          attachParams={fields?.forwardedButtonParams !== undefined}
          forwardedButtonParams={fields?.forwardedButtonParams}
        >
          <Flexbox alignItems="center" justifyContent="space-around">
            {fields?.text}
            {iconSrc && (
              <Box
                marginLeft="sm"
                marginRight="s"
                style={{ position: 'relative' }}
              >
                <img src={iconSrc} alt={iconAlt} loading="lazy" />
              </Box>
            )}
          </Flexbox>
        </LinkButton>
      );

    case 'disclaimer':
      return (
        <Text
          as="p"
          fontSize={{
            0: 'small_p_0',
            desktop: 'small_p_tablet'
          }}
          colour={colour}
        >
          {fields?.text}
        </Text>
      );

    case 'imageWith7030Text':
      return <ImageWith7030TextEmbed {...node?.data?.target?.fields} />;

    case 'learningCenterBannerCta':
      return <BannerCTA {...(fields as ILearningCenterBannerCtaFields)} />;

    case 'phoneNumberField':
      return (
        <PhoneNumberWrapper>
          <CtaWidget
            id={`phone-number-widget-${sys.id}`}
            module="Rich Text"
            errorPosition="bottom"
            width="100%"
            sysId="legacyCta"
            designType={ctaWidgetEnum.INBLOCK_PHONE_NUMBER_V1}
            placeholder={DEFAULT_PLACEHOLDER}
            label={DEFAULT_LABEL}
            cfButtonColor={fields?.buttonBackgroundColor || 'green'}
            cfButtonHoverColor={
              fields?.buttonHoverBackgroundColor || 'sweetmint'
            }
            buttonContent={DEFAULT_BUTTON_TEXT}
          />
        </PhoneNumberWrapper>
      );

    case 'registrationEntrypoint':
      return (
        <CtaWidget
          {...fields}
          id="rich-text-cta"
          module="Rich Text"
          sysId={sys?.contentful?.sys?.id}
          height={56}
          width={180}
        />
      );

    default:
      return (
        <PageBlockPositioner>
          <PageBlocks {...({ pageBlocks: [node?.data?.target] } as any)} />
        </PageBlockPositioner>
      );
  }
}
