import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Asset } from 'contentful';
import { IFooter20Fields } from '../../../../../types/contentful';
import { getImageDataWithHttps } from '~/utils/contentful';

export function FooterImage({
  glTagline,
  glLogo,
  isAffiliateFooter
}: IFooter20Fields) {
  const { description } = glLogo?.fields ?? {};

  return (
    <>
      {!isAffiliateFooter ? (
        <Link
          href="/"
          prefetch={false}
          className="relative h-[32px] w-[206px] tablet:h-[24px] tablet:w-[150px]"
        >
          <Image
            {...getImageDataWithHttps(glLogo as Asset)}
            alt={description ?? 'Greenlight logo'}
            loading="lazy"
            objectFit="contain"
          />
        </Link>
      ) : (
        <span className="relative h-[32px] w-[206px] tablet:h-[24px] tablet:w-[150px]">
          <Image
            {...getImageDataWithHttps(glLogo as Asset)}
            alt={description ?? 'Greenlight logo'}
            loading="lazy"
            objectFit="contain"
          />
        </span>
      )}
      <p className="mt-5 text-lg leading-5 text-white">{glTagline}</p>
    </>
  );
}
