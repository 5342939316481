import React from 'react';
import dynamic from 'next/dynamic';

// Headers and Navigation
const AffiliateHeader = dynamic(() =>
  import('../AffiliateHeader').then((mod) => mod.AffiliateHeader)
);
const Header = dynamic(() => import('../Header').then((mod) => mod.Header));
const Navbar = dynamic(() => import('../Navbar').then((mod) => mod.Navbar));

// Footers
const Footer = dynamic(() => import('../Footer/1.0').then((mod) => mod.Footer));
const Footer2 = dynamic(() =>
  import('../Footer/2.0').then((mod) => mod.Footer2)
);
const FinalCallout = dynamic(() =>
  import('../FinalCallout').then((mod) => mod.FinalCallout)
);

const FinalOverlaySignUp = dynamic(() =>
  import('../FinalOverlaySignUp').then((mod) => mod.FinalOverlaySignUp)
);

// Banners and Promotional Elements
const ExitIntent = dynamic(() =>
  import('../ExitIntent').then((mod) => mod.ExitIntent)
);
const FeatureTicker = dynamic(() => import('../FeatureTicker'));
const WebBanner = dynamic(() =>
  import('../WebBanner').then((mod) => mod.WebBanner)
);

// Split Modules
const SplitForm = dynamic(() =>
  import('../SplitModule/Form').then((mod) => mod.Form)
);
const SplitAssetWithText = dynamic(() =>
  import('../SplitModule/AssetWithText').then((mod) => mod.AssetWithText)
);

// Learning Center Components
const ArticleSectionBlock = dynamic(() =>
  import('../LearningCenter/Components/ArticleSectionBlock').then(
    (mod) => mod.ArticleSectionBlock
  )
);
const FeaturedPosts = dynamic(() =>
  import('../LearningCenter/Components/FeaturedPosts').then(
    (mod) => mod.FeaturedPosts
  )
);
const LearningCenterAbout = dynamic(() =>
  import('../LearningCenter/Components/LearningCenterAbout').then(
    (mod) => mod.LearningCenterAbout
  )
);
const RelatedContent = dynamic(() =>
  import('../LearningCenter/Components/RelatedContent').then(
    (mod) => mod.RelatedContent
  )
);
const SidebarCallout = dynamic(() =>
  import('../LearningCenter/Components/SidebarCallout').then(
    (mod) => mod.SidebarCallout
  )
);
const TilesSection = dynamic(() =>
  import('../LearningCenter/Components/TilesSection').then(
    (mod) => mod.TilesSection
  )
);

const StickySidebar = dynamic(() =>
  import('../LearningCenter/Components/StickySidebar').then(
    (mod) => mod.StickySidebar
  )
);

const BannerCTA = dynamic(() =>
  import('../LearningCenter/Components/BannerCTA').then(
    (mod) => mod.BannerCTA
  )
);

// Metadata
const Metadata = dynamic(() => import('../Metadata'));

// Heros
// Affiliate heroes
const AffiliateHero = dynamic(() =>
  import('../AffiliateHero/1.0').then((mod) => mod.AffiliateHero)
);
const AffiliateHero2 = dynamic(() =>
  import('../AffiliateHero/2.0').then((mod) => mod.AffiliateHero2)
);

// Various hero types
const FeaturedBlogPost = dynamic(() =>
  import('../BlogComponents/FeaturedPost').then((mod) => mod.FeaturedBlogPost)
);
const FullwidthHero = dynamic(() =>
  import('../FullwidthHero').then((mod) => mod.FullwidthHero)
);
const FullBleedHero = dynamic(() =>
  import('../FullBleedHero').then((mod) => mod.FullBleedHero)
);
const PerformanceHero = dynamic(() =>
  import('../PerformanceHero').then((mod) => mod.PerformanceHero)
);
const BackgroundCoverHero = dynamic(() =>
  import('../LearningCenter/Components/BackgroundCoverHero').then(
    (mod) => mod.BackgroundCoverHero
  )
);
const HomepageHero = dynamic(() =>
  import('../HomepageHero').then((mod) => mod.HomepageHero)
);
const MaximumHorizontalHero = dynamic(() =>
  import('../MaximumHorizontalHero').then((mod) => mod.MaximumHorizontalHero)
);
const OverlayHero = dynamic(() =>
  import('../OverlayHero').then((mod) => mod.OverlayHero)
);
const SideBySideHero = dynamic(() =>
  import('../SideBySideHero').then((mod) => mod.SideBySideHero)
);
const StackedHero = dynamic(() =>
  import('../StackedHero').then((mod) => mod.StackedHero)
);
const TextOnlyHero = dynamic(() =>
  import('../TextOnlyHero').then((mod) => mod.TextOnlyHero)
);
const VideoTransitionHero = dynamic(() =>
  import('../VideoTransitionHero').then((mod) => mod.VideoTransitionHero)
);
const CarouselHero = dynamic(() => import('../CarouselHero'));
const AnimatedFullWidthHero = dynamic(() => import('../AnimatedFullWidthHero'));
const AnimatedTextOnlyHero = dynamic(() =>
  import('../AnimatedTextOnlyHero').then((mod) => mod.AnimatedTextOnlyHero)
);

// General Page Components
const AppFeatureAnimation = dynamic(() =>
  import('../AppFeatureAnimation').then((mod) => mod.AppFeatureAnimation)
);
const AsFeaturedOn = dynamic(() =>
  import('../AsFeaturedOn').then((mod) => mod.AsFeaturedOn)
);
const AssetWith5050Text = dynamic(() =>
  import('../AssetWith5050Text').then((mod) => mod.AssetWith5050Text)
);
const BadgeList = dynamic(() =>
  import('../BadgeList').then((mod) => mod.BadgeList)
);
const BodyCopy = dynamic(() =>
  import('../BodyCopy').then((mod) => mod.BodyCopy)
);
const CaliforniaResidentsTable = dynamic(() =>
  import('../Tables/CaliforniaResidentsTable/CaliforniaResidentsTable').then(
    (mod) => mod.CaliforniaResidentsTable
  )
);
const CareersBlock = dynamic(() =>
  import('../CareersBlock').then((mod) => mod.CareersBlock)
);
const CollapsiblePlansList = dynamic(() =>
  import('../CollapsiblePlansList').then((mod) => mod.CollapsiblePlansList)
);
const CompPlansTable = dynamic(() =>
  import('../CompPlansTable').then((mod) => mod.CompPlansTable)
);
const DataRequest = dynamic(() =>
  import('../DataRequest').then((mod) => mod.DataRequest)
);
const HorizontalRule = dynamic(() =>
  import('../HorizontalRule').then((mod) => mod.HorizontalRule)
);
const ImageWith5050Text = dynamic(() =>
  import('../ImageWith5050Text').then((mod) => mod.ImageWith5050Text)
);
const ImageWith7030Text = dynamic(() =>
  import('../ImageWith7030Text').then((mod) => mod.ImageWith7030Text)
);
const InfoBlock = dynamic(() =>
  import('../InfoBlock').then((mod) => mod.InfoBlock)
);
const LegalPageSection = dynamic(() =>
  import('../LegalPageSection').then((mod) => mod.LegalPageSection)
);
const MaxInvestDisclosureTable = dynamic(() =>
  import('../Tables/MaxInvestDisclosureTable').then(
    (mod) => mod.MaxInvestDisclosureTable
  )
);
const Milestone = dynamic(() =>
  import('../Milestone').then((mod) => mod.Milestone)
);
const MultiUpList = dynamic(() =>
  import('../MultiUpList').then((mod) => mod.MultiUpList)
);
const OptionSelect = dynamic(() =>
  import('../OptionSelect').then((mod) => mod.OptionSelect)
);
const Outgrow = dynamic(() => import('../Outgrow').then((mod) => mod.Outgrow));
const PlansList = dynamic(() =>
  import('../PlansList').then((mod) => mod.PlansList)
);
const PlansTable = dynamic(() =>
  import('../PlansTable').then((mod) => mod.PlansTable)
);
const PlansTierTable = dynamic(() =>
  import('../PlansTierTable').then((mod) => mod.PlansTierTable)
);
const PressBar = dynamic(() =>
  import('../PressBar').then((mod) => mod.PressBar)
);
const PressList = dynamic(() =>
  import('../PressList').then((mod) => mod.PressList)
);
const QuickStartGuide = dynamic(() =>
  import('../QuickStartGuide').then((mod) => mod.QuickStartGuide)
);
const SectionWithCollapsibles = dynamic(() =>
  import('../SectionWithCollapsibles').then(
    (mod) => mod.SectionWithCollapsibles
  )
);
const SectionWithPagePointers = dynamic(() =>
  import('../SectionWithPagePointers').then(
    (mod) => mod.SectionWithPagePointers
  )
);
const SocialContentCarousel = dynamic(() =>
  import('../SocialContentCarousel').then((mod) => mod.SocialContentCarousel)
);
const SocialProof = dynamic(() =>
  import('../SocialProof').then((mod) => mod.SocialProof)
);
const SplitModule = dynamic(() =>
  import('../SplitModule').then((mod) => mod.SplitModule)
);
const Storytelling = dynamic(() =>
  import('../Storytelling').then((mod) => mod.Storytelling)
);
const Tables = dynamic(() => import('../Tables').then((mod) => mod.Tables));
const TestimonialSliderBlock = dynamic(() =>
  import('../TestimonialSliderBlock').then((mod) => mod.TestimonialSliderBlock)
);
const TileGrid = dynamic(() =>
  import('../TileGrid').then((mod) => mod.TileGrid)
);
const VariationContainer = dynamic(() =>
  import('../VariationContainer').then((mod) => mod.VariationContainer)
);
const VideoBlock = dynamic(() =>
  import('../VideoBlock').then((mod) => mod.VideoBlock)
);
const VideoBlockFullWidth = dynamic(() =>
  import('../VideoBlockFullWidth').then((mod) => mod.VideoBlockFullWidth)
);
const VideoWith5050Text = dynamic(() =>
  import('../VideoWith5050Text').then((mod) => mod.VideoWith5050Text)
);

const ContentBlock5050 = dynamic(() =>
  import('../ContentBlock5050').then((mod) => mod.ContentBlock5050)
);

const CommunityForum = dynamic(() =>
  import('../CommunityForum').then((mod) => mod.CommunityForum)
);
const FinancialCalculator = dynamic(() =>
  import('../SavingsCalculator').then((mod) => mod.SavingsCalculator)
);

// Import Types
import {
  IAffiliateHeroFields,
  IBackgroundCoverHeroFields,
  ICarouselHeroFields,
  IFeaturedBlogPostFields,
  IFullwidthHero20Fields,
  IHomepageHeroFields,
  IFullBleedHeroFields,
  IMaximumHorizontalHeroFields,
  IOverlayHeroFields,
  IPerformanceHeroFields,
  ISideBySideHeroFields,
  IStackedHeroFields,
  IStaticFullWidthHeroFields,
  IAnimatedTextOnlyHeroFields,
  ITextOnlyHeroFields
} from '@/types/contentful';
import type { AffiliateHero2Props } from '../AffiliateHero/2.0';
import CtaWidget from '../CtaWidget';
import { ExperimentGroupContainer } from '../ExperimentGroupContainer';

export default function Blocks({
  sys,
  fields,
  isActive = false,
  seoProps = {}
}: any) {
  switch (sys?.contentType?.sys?.id) {
    case 'affiliateHeader':
      return <AffiliateHeader {...fields} />;

    case 'staticFullWidthHero':
      return (
        <AnimatedFullWidthHero {...(fields as IStaticFullWidthHeroFields)} />
      );

    case 'header':
      return <Header {...fields} />;

    case 'header2':
      return <Navbar {...fields} />;

    //  CTA's
    case 'registrationEntrypoint':
      return <CtaWidget {...fields} />;

    // Web Banner
    case 'webBanner':
      return <WebBanner {...fields} />;

    // Heroes
    case 'affiliateHero20':
      return <AffiliateHero2 {...(fields as AffiliateHero2Props)} />;

    case 'animatedTextOnlyHero':
      return (
        <AnimatedTextOnlyHero {...(fields as IAnimatedTextOnlyHeroFields)} />
      );

    case 'featuredBlogPost':
      return <FeaturedBlogPost {...(fields as IFeaturedBlogPostFields)} />;

    case 'fullwidthHero20':
      return <FullwidthHero {...(fields as IFullwidthHero20Fields)} />;

    case 'fullBleedHero':
      return <FullBleedHero {...(fields as IFullBleedHeroFields)} />;

    case 'homepageHero':
      return <HomepageHero {...(fields as IHomepageHeroFields)} />;

    case 'overlayHero':
      return <OverlayHero {...(fields as IOverlayHeroFields)} />;

    case 'performanceHero':
      return <PerformanceHero {...(fields as IPerformanceHeroFields)} />;

    case 'stackedHero':
      return <StackedHero {...(fields as IStackedHeroFields)} />;

    case 'textOnlyHero':
      return <TextOnlyHero {...(fields as ITextOnlyHeroFields)} />;

    case 'videoTransitionHero':
      return <VideoTransitionHero {...fields} />;

    // Page Blocks
    case 'appFeatureAnimation':
      return <AppFeatureAnimation {...fields} />;

    case 'asFeaturedOn':
      return <AsFeaturedOn />;

    case 'assetWith5050Text':
      return <AssetWith5050Text sysId={sys.id} {...fields} />;

    case 'badgeList':
      return <BadgeList sysId={sys.id} {...fields} />;

    case 'bodyCopy':
      return <BodyCopy {...fields} />;

    case 'careersBlock':
      return <CareersBlock {...fields} />;

    case 'communityForum':
      return <CommunityForum {...fields} />;

    case 'exitIntent':
      return <ExitIntent {...fields} />;

    case 'financialCalculatorInputModule':
      return <FinancialCalculator {...fields} />;

    case 'imageWith5050Text':
      return <ImageWith5050Text sysId={sys.id} {...fields} />;

    case 'imageWith7030Text':
      return <ImageWith7030Text {...fields} />;

    case 'infoBlock':
      return <InfoBlock {...fields} />;

    case 'milestone':
      return <Milestone sysId={sys.id} {...fields} />;

    case 'multiUpList':
      return <MultiUpList sysId={sys.id} {...fields} />;

    case 'optionSelect':
      return <OptionSelect {...fields} />;

    case 'outgrow':
      return <Outgrow {...fields} />;

    case 'plansList':
      return <PlansList {...fields} />;

    case 'plansList20':
      return <CollapsiblePlansList {...fields} />;

    case 'plansTable':
      return <PlansTable {...fields} />;

    case 'plansTable20':
      return <CompPlansTable {...fields} />;

    case 'plansTable30':
      return <PlansTierTable {...fields} />;

    case 'pressList':
      return <PressList {...fields} />;

    case 'pressBar':
      return <PressBar {...fields} />;

    case 'sectionWithCollapsibles':
      return <SectionWithCollapsibles sysId={sys.id} {...fields} />;

    case 'sectionWithPagePointers':
      return <SectionWithPagePointers {...fields} />;

    case 'socialContentCarousel':
      return <SocialContentCarousel {...fields} />;

    case 'socialProof':
      return <SocialProof {...fields} />;

    case 'splitModule':
      return <SplitModule {...fields} />;

    case 'storytelling':
      return <Storytelling sysId={sys.id} {...fields} />;

    case 'testimonialSlider':
      return <TestimonialSliderBlock {...fields} />;

    case 'videoBlock':
      return <VideoBlock {...fields} />;

    case 'videoBlockFullwidth':
      return <VideoBlockFullWidth {...fields} />;

    case 'videoWith5050Text':
      return <VideoWith5050Text {...fields} />;

    case 'table':
      return <Tables {...fields} />;

    // Split Modules
    case 'splitAssetWithText':
      return <SplitAssetWithText {...fields} />;

    case 'splitForm':
      return <SplitForm {...fields} />;

    case 'contentBlock5050':
      return <ContentBlock5050 {...fields} />;

    // Footers
    case 'footer':
      return <Footer {...fields} />;

    case 'footer20':
      return <Footer2 {...fields} />;

    case 'finalCallout':
      return <FinalCallout sysId={sys.id} {...fields} />;

    case 'finalOverlaySignUp':
      return <FinalOverlaySignUp {...fields} />;

    case 'seoMetadata':
      if (isActive) {
        const metaProps = {
          ...seoProps.page,
          seoMetadata: seoProps.pageBlock
        };
        return <Metadata {...metaProps} />;
      }
      return <></>;

    // Learning Center
    case 'learningCenterAboutBlock':
      return <LearningCenterAbout {...fields} />;

    case 'learningCenterArticleSectionBlock':
      return <ArticleSectionBlock {...fields} />;

    case 'learningCenterTilesSection':
      return <TilesSection {...fields} />;

    case 'learningCenterNewPopularArticles':
      return <FeaturedPosts {...fields} />;

    case 'learningCenterRelatedArticles':
      return <RelatedContent {...fields} />;

    case 'learningCenterSidebarCallout':
      return <SidebarCallout {...fields} />;

    case 'learningCenterStickySignup':
      return <StickySidebar {...fields} />;

    case 'learningCenterBannerCta':
      return <BannerCTA {...fields} />;

    case 'backgroundCoverHero':
      return (
        <BackgroundCoverHero {...(fields as IBackgroundCoverHeroFields)} />
      );

    case 'carouselHero':
      return <CarouselHero {...(fields as ICarouselHeroFields)} />;

    case 'featureTicker':
      return <FeatureTicker {...fields} />;

    case 'affiliateHero':
      return <AffiliateHero {...(fields as IAffiliateHeroFields)} />;

    case 'maximumHorizontalHero':
      return (
        <MaximumHorizontalHero {...(fields as IMaximumHorizontalHeroFields)} />
      );

    case 'sideBySideHero':
      return <SideBySideHero {...(fields as ISideBySideHeroFields)} />;

    case 'variationContainer':
      return <VariationContainer {...fields} sysId={sys?.id} />;

    case 'californiaResidentTable':
      return <CaliforniaResidentsTable />;

    case 'dataRequestForm':
      return <DataRequest {...fields} />;

    case 'horizontalRule':
      return <HorizontalRule {...fields} />;

    case 'legalPageSection':
      return <LegalPageSection {...fields} />;

    case 'maxInvestDisclosureTable':
      return <MaxInvestDisclosureTable />;

    case 'quickStartGuide':
      return <QuickStartGuide {...fields} />;

    case 'tileGrid':
      return <TileGrid {...fields} />;

    case 'experimentGroupContainer':
      return <ExperimentGroupContainer {...fields} />;

    default:
      return null;
  }
}
