import React from 'react';
import dynamic from 'next/dynamic';

import { IPageFields } from '../../../types/contentful';

import { usePageDataContext } from '../../context/PageData';

const FinalCallout = dynamic(() =>
  import('../FinalCallout').then((mod) => mod.FinalCallout)
);

const FinalOverlaySignUp = dynamic(() =>
  import('../FinalOverlaySignUp').then((mod) => mod.FinalOverlaySignUp)
);

const VariationContainer = dynamic(() =>
  import('../VariationContainer').then((mod) => mod.VariationContainer)
);

export function Callouts({ finalCtaType }: IPageFields) {
  const {
    page: { slug } = {},
    config: { finalCta, hideFinalCta } = {},
    articleConfig: { globalFinalCta, regCTAToggle } = {}
  } = usePageDataContext();

  // If hideFinalCta or regCTAToggle is true, return null
  if (hideFinalCta || regCTAToggle) {
    return null;
  }

  // Determine the CTA hierarchy based on the slug
  const cta = slug?.includes('learning-center')
    ? finalCtaType || globalFinalCta || finalCta
    : finalCtaType || finalCta;

  // Extract sys and fields from the selected CTA
  const { sys, fields }: any = cta || {};

  // Return final CTA variations based on the content type
  switch (sys?.contentType?.sys?.id) {
    case 'finalCallout':
      return <FinalCallout sysId={sys.id} {...fields} />;

    case 'finalOverlaySignUp':
      return <FinalOverlaySignUp sysId={sys.id} {...fields} />;

    case 'variationContainer':
      // Do not lazy load
      return <VariationContainer {...fields} sysId={sys?.id} />;

    default:
      return null;
  }
}
